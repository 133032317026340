import { ref } from "vue";
import c3api from "../c3api";
import { useToast } from "vue-toastification";
import { yearMonthDayFormat } from "@/utils/dateUtils";

export default function useTransportOrders() {

    const orderLoading = ref(0);
    const order = ref(null);
    const history = ref([]);
    const cargoTypes = ref([]);
    const additionalServices = ref([]);
    const activeLoadsList = ref([]);
    const documents = ref(null);
    const toast = useToast()
    const orderStatuses = ref([]);
    const billingActivities = ref([]);

    const fetchOrderStatus = async () => {
        try {
            orderLoading.value++;
            const response = await c3api.get(`/transport_orders/transport_statuses`);
            return (orderStatuses.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            orderLoading.value--;
        }
    };

    const changeOrderStatus = async (id, updatedData) => {
        try {
            orderLoading.value++;
            const response = await c3api.post(`/transport_orders/${id}/status`, updatedData);
            toast.success(`Order status changed successfully`);
            return (response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            orderLoading.value--;
        }
    }

    const fetchTransportOrder = async (id) => {
        try {
            orderLoading.value++;
            const response = await c3api.get(`/transport_orders/${id}`);
            return (order.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            orderLoading.value--;
        }
    };

    const fetchDocuments = async (id) => {
        try {
            orderLoading.value++;
            const response = await c3api.get(`/transport_orders/${id}/order_documents?expand=created_by`);
            return (documents.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            orderLoading.value--;
        }
    };

    const fetchTransportOrderHistory = async (id) => {
        try {
            orderLoading.value++;
            const response = await c3api.get(`/transport_orders/${id}/history`);
            return (history.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            orderLoading.value--;
        }
    };    
    
    const fetchActiveLoads = async () => {
        try {
            orderLoading.value++;
            const response = await c3api.get(`/transport_orders/loads/active?expand=driver`);
            response.data.data.forEach(load => {
                load.created_at = yearMonthDayFormat(load.created_at);
            });
            return (activeLoadsList.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            orderLoading.value--;
        }
    }

    const fetchCargoTypes = async () => {
        try {
            orderLoading.value++;
            const response = await c3api.get(`/transport_orders/cargo_types`);
            for (const key in response.data.data) {
                cargoTypes.value.push(response.data.data[key]);
            }
            return (cargoTypes.value);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            orderLoading.value--;
        }
    }

    const fetchAdditionalServices = async () => {
        try {
            orderLoading.value++;
            const response = await c3api.get(`/transport_orders/additional_services`);
            return (additionalServices.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            orderLoading.value--;
        }
    }

    const createTransportOrder = async (formData) => {
        try {
            orderLoading.value++;
            const response = await c3api.post(`/transport_orders`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            toast.success("Transport order created successfully!");
            return (response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            orderLoading.value--;
        }
    }

    const updateTransportOrder = async (id, params) => {
        try {
            orderLoading.value++;
            const response = await c3api.patch(`/transport_orders/${id}`, params);
            toast.success("Transport order updated successfully!");
            return (response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            orderLoading.value--;
        }
    }

    const printDocs = async (apiUrl) => {
        try {
            orderLoading.value++;
            const response = await c3api.get(apiUrl, { responseType: 'blob' });
            const url = URL.createObjectURL(response.data);
            window.open(url, '_blank');
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            orderLoading.value--;
        }
    };

    const fetchBillableActivities = async (id) => {
        try {
            orderLoading.value++;
            const response = await c3api.get(`/order_bills/${id}/order_billable_activities`);
            return (billingActivities.value = response.data.data);
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            orderLoading.value--;
        }
    }

    const saveBillableActivities = async (id, params) => {
        try {
            orderLoading.value++;
            const response = await c3api.post(`/order_bills/${id}/order_billable_activities`, params);
            toast.success("Billable activities saved successfully.");
            return response.data.data;
        } catch (error) {
            toast.error(error.response.data.data.join(". "));
        } finally {
            orderLoading.value--;
        }
    }


    return {
        orderLoading,
        order,
        changeOrderStatus,
        fetchOrderStatus,
        orderStatuses,
        fetchTransportOrder,
        fetchDocuments,
        documents,
        fetchTransportOrderHistory,
        history,
        fetchCargoTypes,
        cargoTypes,
        fetchAdditionalServices,
        additionalServices,
        fetchActiveLoads,
        activeLoadsList,
        createTransportOrder,
        updateTransportOrder,
        printDocs,
        fetchBillableActivities,
        billingActivities,
        saveBillableActivities,
    }
}